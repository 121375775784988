import React from "react"
import Layout from "../components/layout"
import {Helmet} from 'react-helmet';

const AboutPage = () => (
    <Layout>
        <Helmet>
            <title>About ManagersApp</title>
            <meta name="description" content="Managing well is hard, even for those with experience. Get Started for FREE and Level Up Your Management Skills"></meta>
        </Helmet>
        <div className={"page-header"}>
            <h1>What’s ManagersApp?</h1>
            <p style={{ paddingLeft: "20%", paddingRight: "20%" }}>
                Managing well is hard, even for those with experience. At ManagersApp we believe engineering managers could use more help.
                ManagersApp provides the tools needed to apply engineering management best practices in your work for better outcomes.
            </p>
        </div>
        <div className={"container"}>
            <div className={"targets"}>
                <div className={"title"}>
                    <h2>We Are Here For</h2>
                </div>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <div className={"item"}>
                            {/* <div><img alt={"Information"} src={informationImage} /></div> */}
                            <h3>Engineering Managers, Team Leads</h3>
                            <p>
                                <br />
                                ManagersApp is built for engineering managers and team leads who are responsible for the output of their teams. Modules such as
                                one-on-one, team pulse and performance help EMs and TLs focus on enabling and growing their teams for more impactful outcomes.
                                <br /><br />
                                The responsibilities of EMs and TLs cover a large area. At ManagersApp our goal is to help them get better results through their teams.
                            </p>
                        </div>
                    </div>

                    <div className={"col-6"}>
                        <div className={"item"}>
                            {/* <img alt={"CTOs, Head Of Engineering"} src={eventImage} /> */}
                            <h3>CTOs, Head Of Engineers</h3>
                            <p>
                                <br />
                                The motivation behind building ManagersApp was to help engineering leaders achieve better consistency across their teams
                                in the following areas: delivering impactful results, caring for system reliability, making good hiring decisions and growing their people. <br /><br />No two teams
                                are the same but we believe there are foundational pieces that should be shared across teams to help achieve good and consistent
                                outcomes.
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className={"row"}>
                    <div className={"col-6"}>
                        <div className={"item"}>
                            <img alt={"Productivity"} src={ProductivityImage}/>
                            <h3>Raise productivity</h3>
                            <p>Many factors are affecting the staff's productivity in the workplace, the relationship between manager and staff and environmental factors are the main factors, therefore we will focus on these two factors in achieving our goal. Ultimately, increasing employee's productivity results in increasing the quality of products and services that are going to be provided.</p>
                        </div>
                    </div>

                    <div className={"col-6"}>
                        <div className={"item"}>
                            <img alt={"Happiness"} src={happinessImage}/>
                            <h3>Boost motivation and happiness</h3>
                            <p>It is Obvious that a happy employee will make the environment happy and energetic! We are constantly attempting to provide solutions for maximizing employee’s motivation and joy.</p>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </Layout>
)

export default AboutPage
